import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import hiringImage from '../assets/featured.jpg';
import wpbsImage from '../assets/wpbs.jpeg.jpg';
import algaImage from '../assets/alga.jpeg.jpg';
import jaicoImage from '../assets/Jaico.jpeg.jpg';
import dreamImage from '../assets/Dream.jpg';
import solexImage from '../assets/Solex.jpeg.jpg';
import swissImage from '../assets/Swiss360.jpeg.jpg';
import mattechImage from '../assets/Mattech.jpeg.jpg';

const Featured = () => {
  const carouselItems = [
    { imageSrc: hiringImage, title: 'Mortal Combat' },
    { imageSrc: wpbsImage, title: 'Speed Racer' },
    { imageSrc: algaImage, title: 'Another Movie' },
    { imageSrc: dreamImage, title: 'Fast & Furious 8' },
    { imageSrc: jaicoImage, title: 'Fast & Furious 9' },
    { imageSrc: solexImage, title: 'Fast & Furious 10' },
    { imageSrc: swissImage, title: 'Another Movie' },
    { imageSrc: mattechImage, title: 'Fast & Furious 9' },
  ];

  return (
    <div className="w-full mt-10 flex justify-center items-center">
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        modules={[EffectCoverflow, Autoplay, Pagination]}
        className="swiper-container"
        breakpoints={{
          100: {
            slidesPerView: 1, // 1 slide for small mobile
          },
          400: {
            slidesPerView: 2, // 2 slides for larger mobile screens
          },
          768: {
            slidesPerView: 3, // 3 slides for tablets
          },
          1024: {
            slidesPerView: 4, // 4 slides for desktop
          },
          1200: {
            slidesPerView: 5, // 5 slides for larger screens
          },
        }}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        }}
      >
        {carouselItems.map((item, index) => (
          <SwiperSlide key={index} className="relative flex justify-center">
            <div className="carousel-item relative w-[200px] h-[300px] md:w-[300px] md:h-[400px] p-2 transition-transform duration-500 ease-in-out hover:scale-105 overflow-hidden rounded-lg bg-white">
              <div
                className="absolute inset-0 bg-cover bg-center"
                style={{ backgroundImage: `url(${item.imageSrc})` }}
              />
              <div className="absolute bottom-2 left-2 text-white">
                <h3 className="text-lg md:text-xl font-bold">{item.title}</h3>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Featured;
