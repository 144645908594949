import React, { useRef, useState } from 'react';
import { motion, useInView } from 'framer-motion';
import { AiFillStar, AiOutlineStar, AiOutlineHeart, AiFillHeart } from 'react-icons/ai'; 
import Navbar from './Navbar'; 
import solexImage from '../assets/Solex.jpeg.jpg';
import wellmedImage from '../assets/WellMed.jpeg.jpg';

const Shop = () => {
  const cardsData = [
    {
      title: 'Elegant Jewelry',
      description: 'Explore our exclusive collection of elegant jewelry designed to make you stand out.',
      image: solexImage,
      rating: 4,
      location: 'New York, USA',
    },
    {
      title: 'Luxurious Watches',
      description: 'Discover the finest watches that combine luxury and precision.',
      image: wellmedImage,
      rating: 5,
      location: 'Los Angeles, USA',
    },
    {
      title: 'Modern Art Pieces',
      description: 'Add a touch of modern art to your space with our curated selection.',
      image: 'https://www.spyne.ai/blogs/wp-content/uploads/2023/02/Untitled-1-copy-1-1-1024x576.webp',
      rating: 3,
      location: 'Chicago, USA',
    },
    {
      title: 'Luxury Cars',
      description: 'Experience the thrill of driving the latest luxury cars.',
      image: 'https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/b4322b71800111.5bd181970c91f.jpg',
      rating: 4,
      location: 'Houston, USA',
    },
  ];

  return (
    <>
      <Navbar />
      <div className="container mx-auto px-4 py-4 mt-2"> {/* Adjusted padding and margin */}
        <div className="text-center mb-4"> {/* Adjusted bottom margin */}
          <motion.h1
            className="text-2xl sm:text-3xl md:text-4xl font-sans font-bold text-gray-900"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.2 }}
          >
            Trending In Your Area
          </motion.h1>
        </div>
        {/* Grid layout with card size adjustments */}
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 md:gap-6">
          {cardsData.map((card, index) => (
            <Card
              key={index}
              title={card.title}
              description={card.description}
              image={card.image}
              rating={card.rating}
              location={card.location}
            />
          ))}
        </div>
      </div>
    </>
  );
};

const Card = ({ title, description, image, rating, location }) => {
  const [isInWishlist, setIsInWishlist] = useState(false);
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });

  return (
    <motion.div
      ref={ref}
      className="shadow-lg rounded-lg overflow-hidden relative min-w-[200px] sm:min-w-[220px] md:min-w-[250px] h-[320px] sm:h-[350px] transform transition-transform hover:scale-105"
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.5 }}
    >
      <img src={image} alt={title} className="w-full h-40 object-cover sm:h-48" />
      <button
        className="absolute top-4 right-4 bg-white p-2 rounded-full shadow-md hover:bg-gray-100 transition"
        onClick={() => setIsInWishlist(!isInWishlist)}
      >
        {isInWishlist ? (
          <AiFillHeart className="text-red-500" />
        ) : (
          <AiOutlineHeart className="text-gray-500" />
        )}
      </button>
      <div className="p-4 bg-white border-t border-gray-200">
        <h2 className="text-md sm:text-lg font-semibold text-gray-800 mb-2">{title}</h2>
        <p className="text-sm sm:text-base text-gray-600 mb-2">{description}</p>
        <div className="flex items-center mb-2">
          {Array.from({ length: 5 }, (_, i) =>
            i < rating ? (
              <AiFillStar key={i} className="text-yellow-400" />
            ) : (
              <AiOutlineStar key={i} className="text-yellow-400" />
            )
          )}
        </div>
        <p className="text-sm text-gray-500">{location}</p>
      </div>
    </motion.div>
  );
};

export default Shop;
