import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

// Sample categories
const categories = [
  { name: 'Restaurants', icon: '🍽️' },
  { name: 'Hotels', icon: '🏨' },
  { name: 'Beauty Spa', icon: '💆' },
  { name: 'Home Decor', icon: '🛋️' },
  { name: 'Wedding Planning', icon: '👰' },
  { name: 'Education', icon: '🎓' },
  { name: 'Rent & Hire', icon: '🔧' },
  { name: 'Hospitals', icon: '🏥' },
  { name: 'Contractors', icon: '👷' },
  { name: 'Pet Shops', icon: '🐕' },
  { name: 'PG/Hostels', icon: '🏠' },
  { name: 'Estate Agent', icon: '🏘️' },
  { name: 'Dentists', icon: '🦷' },
  { name: 'Gym', icon: '🏋️' },
  { name: 'Consultants', icon: '💼' },
  { name: 'Event Organisers', icon: '🎉' },
  { name: 'Driving Schools', icon: '🚗' },
  { name: 'Packers & Movers', icon: '🚛' },
  { name: 'Courier Service', icon: '📦' }
];

const CategoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 20px;
  justify-content: center;
`;

const CategoryItem = styled(motion.div)`
  text-align: center;
  padding: 8px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  flex: 0 1 6%; /* Default width for desktop view to fit 13 items */

  &:hover {
    transform: translateY(-3px);
  }

  /* Responsive behavior for mobile view */
  @media (max-width: 768px) {
    flex: 0 1 12%; /* Adjust for mobile to fit more items in one row */
    padding: 4px; /* Reduce padding for mobile */
  }

  /* Extra small screen behavior (e.g., very small mobile devices) */
  @media (max-width: 480px) {
    flex: 0 1 20%; /* Further adjust for very small screens */
    padding: 2px; /* Further reduce padding for smaller screens */
  }
`;

const IconContainer = styled.div`
  font-size: 20px; /* Adjust font size for mobile */
  margin-bottom: 5px;
`;

const CategoryName = styled.div`
  font-size: 10px;
  font-weight: bold;
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 10px; /* Reduce margin for mobile */
`;

const ToggleButton = styled(motion.button)`
  padding: 4px 8px; /* Reduced padding for mobile */
  font-size: 12px; /* Reduced font size for mobile */
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  /* Responsive behavior for mobile view */
  @media (max-width: 768px) {
    padding: 4px 8px; /* Further reduce padding for mobile */
    font-size: 12px; /* Further reduce font size for mobile */
  }
`;

const Categories = () => {
  const [showMore, setShowMore] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initial state based on current width

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener for window resizing
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleToggle = () => {
    setShowMore(!showMore);
  };

  // Logic to determine how many categories to show based on screen size and 'Show More' state
  const getCategoriesToShow = () => {
    const limit = showMore ? categories.length : isMobile ? 8 : 13;
    return categories.slice(0, limit);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <motion.h2
        className="text-2xl font-bold text-center mb-4 text-gray-900" /* Reduced margin */
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        Explore Popular Categories
      </motion.h2>
      
      <CategoriesContainer>
        {getCategoriesToShow().map((category, index) => (
          <CategoryItem
            key={index}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            whileHover={{ scale: 1.05, boxShadow: '0 4px 8px rgba(0,0,0,0.2)' }}
            whileTap={{ scale: 0.95 }}
          >
            <IconContainer>{category.icon}</IconContainer>
            <CategoryName>{category.name}</CategoryName>
          </CategoryItem>
        ))}
      </CategoriesContainer>
      
      <ButtonContainer>
        <ToggleButton
          onClick={handleToggle}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          {showMore ? 'Show Less' : 'Show More'}
        </ToggleButton>
      </ButtonContainer>
    </div>
  );
};

export default Categories;
